.featuredContent h5.productsSection {
    padding: 20px 0;
    border: 1px solid #ededed;
}

.productsSection .row {
    justify-content: center;
}

.productsSection .row.featuredRow {
    justify-content: start;
}

.featuredImg,
.featuredFigure {
    position: relative;
}

.featuredImg {
    overflow: hidden;
}

.featuredImg img {
    transition: .4s ease-in-out;
    min-height: 210px;
    object-fit: cover;
    width: 100%;
}

.featuredImg:hover img {
    transform: scale(1.1);
}

.featuredImg:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1111119e;
    z-index: 1;
    display: none;
}



.hotList {
    position: absolute;
    left: 10px;
    top: 10px;
    margin: 0;
    padding: 0;
}

.hotList li a {
    display: inline-block;
    padding: 4px 6px;
    width: 43px;
    color: #333;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
}

.hotList li:last-child a {
    background-color: #25782e;
    color: #fff;
}

.featuredContent {
    padding: 15px 0 25px;
    background-color: #fff;
    text-align: center;
    /* border: 1px solid #ededed; */

}

.relatedProductSec .featuredContent,
.trendingProductSe .featuredContent {
    box-shadow: 0px 15px 10px -15px #a9a9a9;
}

.featuredContent h6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #878787;
    display: none;
}

.featuredContent h5 {
    text-transform: capitalize;
    font-size: 19px;
    height: 45px;
}

.featuredContent h5 a {
    color: #856007;
}

.featuredContent h5 a:hover {
    color: #000;
}

.featuredContent .rating svg {
    color: #fb641b;
}

.featuredOption select {
    background: #bdbdbd;
    width: 120px;
    height: 30px;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid #b1b1b1;
    font-size: 13px;
    display: none;
}

.rateDigit {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    margin: 10px 0;
}

.rateDigit .cross {
    text-decoration: line-through;
    color: #878787;
    font-size: 14px;
}

.rateDigit .currentPrice {
    font-weight: 600;
    color: #131313;
    font-size: 18px;
}


@media (max-width: 991px) {
    .fisherman-content {
        text-align: center;
    }

    .brandsItem ul {
        flex-wrap: wrap;
        row-gap: 15px;
        justify-content: center;
    }

    .brandsItem ul li {
        width: 20%;
    }
}

@media (max-width: 767px) {
    .brandsItem ul li {
        width: 47%;
    }

    .brandsItem ul li a {
        display: flex;
        justify-content: center;
    }
}